import React, { Suspense, lazy } from "react";

import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import configureStore from "./config";
import { Navigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-pagination-js/dist/styles.css";
// import "./scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
// progressbar;
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-phone-input-2/lib/style.css";
import 'react-quill/dist/quill.snow.css';

import { Auth } from "./methods/auth";

/************ store configration *********/


const SignUp = React.lazy(() => import("./pages/Signup"));
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"));
const Resetpassword = React.lazy(() => import("./pages/Resetpassword"));
const Profile = React.lazy(() => import("./pages/Profile"));
const EditProfile = React.lazy(() => import("./components/Profile/Edit"));
const ReteriveProductData = React.lazy(() => import("./pages/ProductDashboard/RetriveProduct"));
const ChangePassword = React.lazy(() => import("./components/Profile/ChangePassword"));
const Plans = React.lazy(() => import("./pages/Plan"));
const Cards = React.lazy(() => import("./pages/Cards"));
const AddEditCards = React.lazy(() => import("./pages/Cards/AddEdit"));
const CardsDetail = React.lazy(() => import("./pages/CardsDetail"));
const ProductDetail = React.lazy(() => import("./pages/Products/Detail/ProductDetail"));
const ProductDetail1 = React.lazy(() => import("./pages/Products/Detail1/ProductDetail"));
const POS = React.lazy(() => import("./pages/POS"));
const MarketingSoftware = React.lazy(() => import("./pages/POS/MarketingSoftware"));
const Orders = React.lazy(() => import("./pages/POS/Orders/Orders"));
const CompanyDetails = React.lazy(() => import("./pages/CompanyDetails"));
const Salesdashboard = React.lazy(() => import("./pages/SalesDashboard"));
const Resellerdashboard = React.lazy(() => import("./pages/SalesDashboard/ResellerDashboard"));
const Marketingdashboard = React.lazy(() => import("./pages/MarketingDashboard"));
const Financialdashboard = React.lazy(() => import("./pages/FinancialDashboard"));
const ActivePlan = React.lazy(() => import("./pages/Plan/ActivePlan"));
const OrderDetail = React.lazy(() => import("./pages/POS/Orders/OrderDetail"));
const Calendar = React.lazy(() => import("./pages/Calendar"));
const SalesData = React.lazy(() => import("./pages/SalesDashboard/salesData"));
const Productdashboard = React.lazy(() => import("./pages/ProductDashboard"));
const ProductData = React.lazy(() => import("./pages/ProductDashboard/ProductData"));
const CustomerData = React.lazy(() => import("./pages/Customers/CustomerData"));
const Customers = React.lazy(() => import("./pages/Customers"));
const ResellerDatabase = React.lazy(() => import("./pages/ResellerDatabase"));
const Roles = React.lazy(() => import("./pages/Roles"));
const AddEditRole = React.lazy(() => import("./pages/Roles/AddEdit"));
const Users = React.lazy(() => import("./pages/Users"));
const AddEditUser = React.lazy(() => import("./pages/Users/AddEditUser"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Googlereview = React.lazy(() => import("./pages/Reviews/Googlereview"));
const FacebookReviews = React.lazy(() => import("./pages/Reviews/FacebookReviews"));
const Googlereviewsteps = React.lazy(() => import("./pages/Reviews/Googlereviewsteps"));
const step2 = React.lazy(() => import("./pages/Reviews/step2"));
const sitereview = React.lazy(() => import("./pages/Reviews/Reviewsite"));
const POSReviews = React.lazy(() => import("./pages/POS/Reviews"));
const improveContact = React.lazy(() => import("./pages/Reviews/ImproveContacts"));
const CRM = React.lazy(() => import("./pages/CRM"));
const AddEditCRM = React.lazy(() => import("./pages/CRM/AddEdit"));
const ViewReseller = React.lazy(() => import("./pages/ResellerDatabase/view"));
const AddEditReseller = React.lazy(() => import("./pages/ResellerDatabase/AddEditReseller"));
const Thanku = React.lazy(() => import("./pages/Thanku"));
const ReviewDashboard = React.lazy(() => import("./pages/Reviews/ReviewDashboard"));
const ReviewPage = React.lazy(() => import("./pages/Reviews/ReviewPage"));
const ReviewDetail = React.lazy(() => import("./pages/Reviews/ReviewDetail"));
const ViewCRM = React.lazy(() => import("./pages/CRM/View"));
const All_review = React.lazy(() => import("./pages/reviewsAll"));
const MarketingAnalytics = React.lazy(() => import("./pages/MarketingDashboard/MarketingAnalytics"));
const ContractTemplateProducts = React.lazy(() => import("./pages/CRM/ContractTemplateProducts"));
const XeroConnect = React.lazy(() => import("./pages/Xero/XeroConnect"));
const XeroInvoices = React.lazy(() => import("./pages/Xero/Invoices"));
const XeroTransactions = React.lazy(() => import("./pages/Xero/transactions"));
const XeroInvoiceDetail = React.lazy(() => import("./pages/Xero/Invoices/Detail"));
const XeroTransactionDetail = React.lazy(() => import("./pages/Xero/transactions/Detail"));
const OTPpage = React.lazy(() => import("./pages/Signup/OTPpage"));
const CustomerDataDetails = React.lazy(() => import("./pages/CustomersDazboard/Customers/CustomerDetails"));


const { persistor, store } = configureStore();

export default () => {
  localStorage.removeItem('joinedRoom')
  localStorage.removeItem('joinedPlatform')

  const routes = [
    {
      url: '/kpi',
      path: 'KPI'
    },
    {
      url: '/tour-details',
      path: 'TourDetail'
    },
    {
      url: '/tour-details/add',
      path: 'TourDetail/AddEdit',
    },
    {
      url: '/tour-details/edit/:id',
      path: 'TourDetail/AddEdit',
    },
    {
      url: '/tour-details/detail/:id',
      path: 'TourDetail/View'
    },
    {
      url: '/employee',
      path: 'Employee'
    },
    {
      url: '/employee/edit/:id',
      path: 'Employee/AddEdit'
    },
    {
      url: '/employee/add',
      path: 'Employee/AddEdit'
    },
    {
      url: '/employee/detail/:id',
      path: 'Employee/View'
    },
    {
      url: '/activity',
      path: 'Activity'
    },
    {
      url: '/activity/edit/:id',
      path: 'Activity/AddEdit'
    },
    {
      url: '/activity/add',
      path: 'Activity/AddEdit'
    },
    {
      url: '/activity/detail/:id',
      path: 'Activity/View'
    },
    {
      url: '/goal',
      path: 'GoalManager'
    },
    {
      url: '/goal/edit/:id',
      path: 'GoalManager/AddEdit'
    },
    {
      url: '/goal/add',
      path: 'GoalManager/AddEdit'
    },
    {
      url: '/goal/detail/:id',
      path: 'GoalManager/View'
    },
    {
      url: '/task',
      path: 'Tasks'
    },
    {
      url: '/task/edit/:id',
      path: 'Tasks/AddEdit'
    },
    {
      url: '/task/add',
      path: 'Tasks/AddEdit'
    },
    {
      url: '/task/detail/:id',
      path: 'Tasks/View'
    },
    {
      url: '/login',
      path: 'Login'
    },
    {
      url: '/sales/refunds',
      path: 'SalesDashboard/RefundData'
    },
    {
      url: '/coupons',
      path: 'CouponsModule/CouponDashboard'
    },
    {
      url: "/sales/retrievable",
      path: "SalesDashboard/SalesRetriveData"
    },
    {
      url: "/two-factor-auth",
      path: 'TwoFactorAuth'
    },
    {
      url: "/partner",
      path: "PartnerModule/Partner"
    },
    {
      url: "/coupons/add/:id",
      path: "CouponsModule/AddEditCoupon"
    },
    {
      url: "/coupon/:id",
      path: "CouponsModule/ViewCoupons"
    },
    {
      url: "/prepare",
      path: "PDFSIGN/Prepration"
    },
    {
      url: "/signdoc",
      path: "PDFSIGN/SIGNATURE"
    },
    {
      url: "/submit/thanks",
      path: "SubmissinTahnku/SubmissionTanks"
    },
    {
      url: "/dashboard",
      path: "DashboardPages/EnvirenmentDashboard"
    },
    {
      url: "/pos/add",
      path: "POS/AddEdit"
    },
    {
      url: "/pos/edit/:id",
      path: "POS/AddEdit"
    },
    {
      url: "*",
      path: "404"
    },
    {
      url: "/accounting/bills",
      path: "Xero/BIllsToPay"
    },
    {
      url: "/accounting/bill/:id",
      path: "POS/Xero/BIllsToPay/Detail"
    },
  ]

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<div>
          <div className="loaderDiv">
            <div>
              <img
                src="/assets/img/loader.gif"
                alt="logo"
                className="pageLoader"
                width="auto"
                height="auto"
              />
            </div>
          </div>
        </div>} persistor={persistor}>

          <Suspense
            fallback={
              <div>
                <div className="loaderDiv">
                  <div>
                    <img
                      src="/assets/img/loader.gif"
                      alt="logo"
                      className="pageLoader"
                      width="auto"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
            }>
            <Router>
              <Routes>

                {routes.map(itm => {
                  const Element = lazy(() => import(`./pages/${itm.path}`));
                  return (
                    <Route
                      path={itm.url}
                      element={itm.path ? <Element /> : itm?.element}
                    />
                  );
                })}



                <Route path='/' element={<Navigate to="/login" />} />



                <Route
                  exact={true}
                  store={store}
                  path="/otppage"
                  Component={OTPpage}
                />

                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/dashboard/orders"
                  Component={Orders}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/orders/:id"
                  Component={OrderDetail}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile"
                  Component={Profile}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile/edit"
                  Component={EditProfile}
                />
                <Route
                  exact={true}
                  requireAuth={Auth}
                  store={store}
                  path="/profile/change-password"
                  Component={ChangePassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/calendar"
                  Component={Calendar}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/activeplan"
                  Component={ActivePlan}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/product/detail1/:id"
                  Component={ProductDetail}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/product/:id"
                  Component={ProductDetail1}
                />

                <Route
                  exact={true}
                  store={store}
                  path="/company"
                  Component={CompanyDetails}
                />

                <Route
                  exact={true}
                  store={store}
                  path="/signup"
                  Component={SignUp}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/forgotpassword"
                  Component={Forgotpassword}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/resetpassword"
                  Component={Resetpassword}
                />

                <Route
                  exact={true}
                  store={store}
                  path="/sales-data"
                  Component={SalesData}
                />
                <Route
                  exact={true}
                  path="/users"
                  store={store}
                  requireAuth={Auth}
                  Component={Users}
                />
                <Route
                  exact={true}
                  path="/users/add"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditUser}
                />
                <Route
                  exact={true}
                  path="/users/edit/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditUser}
                />

                <Route
                  exact={true}
                  store={store}
                  path="/roles"
                  Component={Roles}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/roles/add"
                  Component={AddEditRole}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/roles/edit/:id"
                  Component={AddEditRole}
                />

                <Route
                  exact={true}
                  path="/product-dashboard"
                  store={store}
                  requireAuth={Auth}
                  Component={Productdashboard}
                />
                <Route
                  exact={true}
                  path="/product-data"
                  store={store}
                  requireAuth={Auth}
                  Component={ProductData}
                />
                <Route
                  exact={true}
                  path="/products/retrivable"
                  store={store}
                  requireAuth={Auth}
                  Component={ReteriveProductData}
                />
                <Route
                  exact={true}
                  path="/customer-data"
                  store={store}
                  requireAuth={Auth}
                  Component={CustomerData}
                />
                <Route
                  exact={true}
                  path="/customers"
                  store={store}
                  requireAuth={Auth}
                  Component={Customers}
                />
                <Route
                  exact={true}
                  path="/reseller-data"
                  store={store}
                  requireAuth={Auth}
                  Component={ResellerDatabase}
                />
                <Route
                  exact={true}
                  path="/reseller-data/add"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditReseller}
                />
                <Route
                  exact={true}
                  path="/reseller-data/edit/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditReseller}
                />
                <Route
                  exact={true}
                  path="/reseller-data/view/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={ViewReseller}
                />
                <Route
                  exact={true}
                  path="/reviews"
                  store={store}
                  requireAuth={Auth}
                  Component={Reviews}
                />
                <Route
                  exact={true}
                  path="/review-dashboard"
                  store={store}
                  requireAuth={Auth}
                  Component={ReviewDashboard}
                />
                <Route
                  exact={true}
                  path="/api/review-connected"
                  store={store}
                  requireAuth={Auth}
                  Component={POSReviews}
                />
                <Route
                  exact={true}
                  path="/api/review"
                  store={store}
                  requireAuth={Auth}
                  Component={Googlereview}
                />
                <Route
                  exact={true}
                  path="/api/review/facebook"
                  store={store}
                  requireAuth={Auth}
                  Component={FacebookReviews}
                />
                <Route
                  exact={true}
                  path="/api/accounting"
                  store={store}
                  requireAuth={Auth}
                  Component={XeroConnect}
                />

                <Route
                  exact={true}
                  path="/accounting/invoices"
                  store={store}
                  requireAuth={Auth}
                  Component={XeroInvoices}
                />
                <Route
                  exact={true}
                  path="/accounting/invoices/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={XeroInvoiceDetail}
                />
                <Route
                  exact={true}
                  path="/accounting/transactions"
                  store={store}
                  requireAuth={Auth}
                  Component={XeroTransactions}
                />
                <Route
                  exact={true}
                  path="/accounting/transactions/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={XeroTransactionDetail}
                />

                <Route
                  exact={true}
                  path="/reviewStep"
                  store={store}
                  requireAuth={Auth}
                  Component={Googlereviewsteps}
                />
                <Route
                  exact={true}
                  path="/step"
                  store={store}
                  requireAuth={Auth}
                  Component={step2}
                />
                <Route
                  exact={true}
                  path="/sitereview"
                  store={store}
                  requireAuth={Auth}
                  Component={sitereview}
                />
                <Route
                  exact={true}
                  path="/improvecontact"
                  store={store}
                  requireAuth={Auth}
                  Component={improveContact}
                />
                <Route
                  exact={true}
                  path="/api/reviewpage"
                  store={store}
                  requireAuth={Auth}
                  Component={ReviewPage}
                />
                <Route
                  exact={true}
                  path="/review/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={ReviewDetail}
                />
                <Route
                  exact={true}
                  path="/crm"
                  store={store}
                  requireAuth={Auth}
                  Component={CRM}
                />
                <Route
                  exact={true}
                  path="/crm/add"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditCRM}
                />
                <Route
                  exact={true}
                  path="/crm/edit/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={AddEditCRM}
                />
                <Route
                  exact={true}
                  path="/crm/view/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={ViewCRM}
                />
                <Route
                  exact={true}
                  path="/crm/products/:id"
                  store={store}
                  requireAuth={Auth}
                  Component={ContractTemplateProducts}
                />


                <Route
                  exact={true}
                  path="/pos"
                  store={store}
                  requireAuth={Auth}
                  Component={POS}
                />
                <Route
                  exact={true}
                  path="/marketing-software"
                  store={store}
                  requireAuth={Auth}
                  Component={MarketingSoftware}
                />
                <Route
                  exact={true}
                  path="/pos/orders"
                  store={store}
                  requireAuth={Auth}
                  Component={Orders}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/pos/orders/orderdetail/:id"
                  Component={OrderDetail}
                />
                <Route
                  exact={true}
                  path="/sales"
                  store={store}
                  requireAuth={Auth}
                  Component={Salesdashboard}
                />
                <Route
                  exact={true}
                  path="/reseller-dashboard"
                  store={store}
                  requireAuth={Auth}
                  Component={Resellerdashboard}
                />

                <Route
                  exact={true}
                  path="/marketing/google-analytics"
                  store={store}
                  requireAuth={Auth}
                  Component={Marketingdashboard}
                />
                <Route
                  exact={true}
                  path="/marketing-analytics"
                  store={store}
                  requireAuth={Auth}
                  Component={MarketingAnalytics}
                />
                <Route
                  exact={true}
                  path="/financial/dashboard"
                  store={store}
                  requireAuth={Auth}
                  Component={Financialdashboard}
                />

                {/* Plans */}
                <Route exact={true} path="/plans" Component={Plans} />
                {/* Cards */}
                <Route
                  exact={true}
                  store={store}
                  path="/card"
                  Component={Cards}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/card/add"
                  Component={AddEditCards}
                />
                <Route
                  exact={true}
                  store={store}
                  path="/card/edit/:id"
                  Component={AddEditCards}
                />

                <Route
                  exact={true}
                  path="/detailcards/:id/:intervalcount/:currencyiso"
                  Component={CardsDetail}
                />
                <Route
                  exact={true}
                  path="/thanku"
                  store={store}
                  Component={Thanku}
                />
                <Route
                  exact={true}
                  path="/allreview"
                  store={store}
                  Component={All_review}
                />
                <Route
                  exact={true}
                  path="/customerdetails/:id"
                  store={store}
                  Component={CustomerDataDetails}
                />


              </Routes>
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv !hidden">
        <div>
          <img
            src="/assets/img/loader.gif"
            width="auto"
            height="auto"
            alt="logo"
            className="pageLoader"
          />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
